import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }
  private CORS_PROXY = "";
  private URL_ROOT = "https://beta.antepulsio.com/";
  // private URL_ROOT = "https://dev.itylearning.com/";

  login(email: string, password: string) {
    // return this.http.get(this.CORS_PROXY + this.URL_ROOT + "api/users/auth?k=xnsLhHdVmPQuUkjqXsq8WYW8XNmG9NaV&l=" + email + "&p=" + password);
    return this.http.get(this.CORS_PROXY + this.URL_ROOT + "api/api_authentication.php?l=" + email + "&p=" + password);
  }

  getCourses(idUser) {
    return this.http.get(this.CORS_PROXY + this.URL_ROOT + "api/api_user.php?uid=" + idUser);
  }

  getResults(idUser)
  {
    return this.http.get(this.CORS_PROXY + this.URL_ROOT + `api/results/userResult.php?uid=${idUser}`);
  }
  getNotifications(idUser) {
    return this.http.get(this.CORS_PROXY + this.URL_ROOT + "api/users/" + idUser + "/allmessages?k=xnsLhHdVmPQuUkjqXsq8WYW8XNmG9NaV");
  }

  sendMessage(idUser, idNotif, message) {
    const formData = new FormData();
    formData.append("UnityAnswer", message);
    return this.http.post(this.CORS_PROXY + this.URL_ROOT + "api/users/" + idUser + "/messages/" + idNotif+ "/reponseunity?k=xnsLhHdVmPQuUkjqXsq8WYW8XNmG9NaV", formData);
  }

  getTeam(idUser) {
    return this.http.get(this.CORS_PROXY + this.URL_ROOT + "api/teams/all.php?uid=" + idUser);
  }

  getUserByTeam(idUser, idTeam) {
    return this.http.get(this.CORS_PROXY + this.URL_ROOT + "api/teams/getUsers.php?uid=" + idUser + "&teamId=" + idTeam);
  }

  createTeam(idUser, teamName) {
    const formData = new FormData();
    formData.append("name", teamName);
    return this.http.post(this.URL_ROOT + `api/teams/create.php?uid=${idUser}`, formData);
  }
  
  deleteTeam(idUser, idTeam) {
    return this.http.get(this.URL_ROOT + `api/teams/delete.php?uid=${idUser}&teamId=${idTeam}`)
  }
  
  getAllUsers(idUser){
    return this.http.get(this.URL_ROOT + `api/teams/allUsers.php?uid=${idUser}`)
  }

 async getDashboardData(idUser) {
    return this.http.get(this.CORS_PROXY + this.URL_ROOT + `api/api_dashboard.php?uid=${idUser}`);
  }

  getMatriculeUser(matricule, key) {
    return this.http.get(this.URL_ROOT + `api/api_externalConnect.php?action=externalConnect&l=${matricule}&k=${key}`);
  }
  getAllModules(idUser) {
    return this.http.get(this.URL_ROOT + `api/modules/all.php?uid=${idUser}`)
  }
  createModule(idUser, zip) {
    const formData = new FormData();
    formData.append("Module", zip);
    return this.http.post(this.URL_ROOT + `api/modules/create.php?uid=${idUser}`, formData);
  }
  updateModule(idUser, idModule, zip) {
    const formData = new FormData();
    formData.append("Module", zip);
    return this.http.post(this.URL_ROOT + `api/modules/update.php?uid=${idUser}&moduleid=${idModule}`, formData);
  }

  deleteModule(idModule) {
    return this.http.get(this.URL_ROOT + `api/modules/delete.php?moduleid=${idModule}`)
  }

  getAllCourses(idUser) {
    return this.http.get(this.URL_ROOT + `api/courses/all.php?uid=${idUser}`)
  }

  createUser(form_data: any) : Observable<Object> {
    let formData = new FormData();
    for (let value of Object.keys(form_data)) {
      if (value === 'role') {
        formData.append(value, form_data[value].id);
      } else if (value === 'user_role'){
        console.log('role', formData['role'].id)
      } else {
        // console.log(value, form_data[value])
        formData.append(value, form_data[value]);
      }
    }
    console.log(formData.get('role'))
    return this.http.post( this.URL_ROOT + `api/user/create.php`, formData);
  }

  updateUser(form_data: any) {

    let formData = new FormData();

    for (let value of Object.keys(form_data)) {
      if (value === 'role') {
        formData.append(value, form_data[value].id);
      } else if (value === 'user_role'){
        console.log('role', formData['role'].id)
      } else {
        formData.append(value, form_data[value]);
      }
    }
    console.log(formData);
    return this.http.post(this.URL_ROOT + `api/user/update.php`, formData);
  }

  removeUser(idUser: string) {
    return this.http.get(this.URL_ROOT + `api/user/delete.php?uid=${idUser}`)
  }
  getSessions(idUser: string) : Observable<Object>  {
    return this.http.get(this.URL_ROOT + `api/api_session.php?uid=${idUser}`);
  }

  createCourse(zip) {
    // const formData = new FormData();
    // formData.append("Module", zip);
    // return this.http.post(this.URL_ROOT + "api/modules/create.php", formData);
  }

  
  deleteCourse(idCourse) {
    // return this.http.get(this.URL_ROOT + `api/modules/delete.php&moduleid=${idCourse}`)
  }
  getSessionInformationOnDashboard(idUser: string, session_rank :string)
  {
    return this.http.get(this.URL_ROOT+`api/api_dashboard.php?uid=${idUser}&slimit=${session_rank}`) ;
  }
  getCourseInformationOnDashboard(idUser: string, course_rank :string)
  {
    return this.http.get(this.URL_ROOT+`api/api_dashboard.php?uid=${idUser}&climit=${course_rank}`) ;
  }

  generateCSVOnDashboard(formDataObject) {
    let httpOptions = {
      headers: new HttpHeaders({}),
      responseType: 'text'
    }
      let formData = new FormData();
      for (const key of Object.keys(formDataObject)) {
        formData.append(key, formDataObject[key]);
      }
      return this.http.post( this.URL_ROOT + `api/results/exportResult.php`, formData, {headers: new HttpHeaders({}), responseType: 'text'});
  }

  addUserInTeam(uid, teamId, userToAddId) : Observable<Object> {
    let formData = new FormData();
    formData.append('uid', uid);
    formData.append('teamId', teamId);
    formData.append('userToAddId', userToAddId);
    return this.http.post( this.URL_ROOT + `api/teams/addUserInTeam.php`, formData);
  }

  removeUsersFromTeam(uidTab: any, teamId: string) : Observable<Object> {
    return this.http.get(this.URL_ROOT + `api/teams/deleteUser.php?uid=${uidTab}&teamId=${teamId}`)
  }

}
