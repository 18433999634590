import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormControl } from '@angular/forms';
import { DataService } from '../data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginErrorComponent } from '../login-error/login-error.component';
import { style } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public forgotPwd = false;
  public calculatedHeight = '42vh';
  
  constructor(
    private router: Router,
    private data: DataService,
    private dialog: MatDialog,
    private translate: TranslateService
    ) {

  }
  ngOnInit(): void {
    const styleForm = window.getComputedStyle(document.getElementById('form-header-login'));
    this.calculatedHeight = `calc(${styleForm.height} + ${styleForm.paddingTop} * 2) / 2)`;
    // login with redirection or LDAP or other //
    if (localStorage.getItem("m_idUser"))
    {
      const user = {
        email: localStorage.getItem("m_Mail"),
        username: localStorage.getItem("m_UserName"),
        surname: localStorage.getItem("m_UserSurname"),
        idUser: localStorage.getItem("m_idUser"),
        role: localStorage.getItem("m_Level"),
        Lang: localStorage.getItem("m_Lang")
      }
      // for developpement //
      if (user.surname === "Dev") {
        user.role = "Manager"
      }
      localStorage.setItem("user", JSON.stringify(user));
      this.router.navigate(['/home']);
    }
  }

  ImproovedLogIn()
  {
    this.router.navigate(['/home']);
  }
  logIn(email: string, password: string) {
 
    if (email && password)  {
      this.data.login(email, password).subscribe(
        data => {
          console.log(JSON.stringify(data));
          if(data["id"]) {
            const user = {
              email: data["Mail"],
              username: data["Login"],
              surname: data["Login"],
              idUser: data["id"],
              role: data["created_at"]
            }
            if (user.surname === "Dev") {
              user.role = "Manager"
            }
            this.translate.setDefaultLang('fr');
            switch (data["m_UserLang"]) {
              case "French":
                this.translate.setDefaultLang('fr');
                break;
              case "English":
                this.translate.setDefaultLang('en');
                break;
              case "Deutsch":
                this.translate.setDefaultLang('de');
                break;
            }
            localStorage.setItem("user", JSON.stringify(user));
            this.router.navigate(['/home']);
          } else {
            this.forgotPwd = true;
            this.dialog.open(LoginErrorComponent);
          }
        }
      )
    }
  }
 // To restore hide function, return this.forgotPwd
  isForgotPwd = () => false ;

  forgotPassword() {
    //window.open("https://ch.itylearning.com/connect/?action=forgotpwd", "_blank");
  }

  onResize(event) {

  }
}
