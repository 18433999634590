import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';


import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ParcoursComponent } from './parcours/parcours.component';
import { LoginErrorComponent } from './login-error/login-error.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountComponent } from './account/account.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TeamsComponent } from './teams/teams.component';
import { CourseComponent } from './course/course.component';
import { ModuleComponent } from './module/module.component';
import { DocumentComponent } from './document/document.component';
import { SkillComponent } from './skill/skill.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule, MatDatepickerModule, MatIconModule, MatNativeDateModule, MatSelectModule, MatSlideToggleModule, MAT_DATE_LOCALE } from '@angular/material';
import { ChartCardComponent } from './chart-card/chart-card.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ScrollbarComponent } from './scrollbar/scrollbar.component';
import { ExternalConnectComponent } from './external-connect/external-connect.component';
import { StartcourseComponent } from './startcourse/startcourse.component';
import { MultiWindowModule } from 'ngx-multi-window';
import { DialogContentImportScorm } from './dialog-content-import-scorm/dialog-content-import-scorm';
import { DndDirective } from './dnd.directive';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { ResultUserComponent } from './result-user/result-user.component';
import {MatRadioButton, MatRadioGroup, MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import { ListComponent } from './utilities/list/list.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ClusterVerticalBarComponent } from './chart/cluster-vertical-bar/cluster-vertical-bar.component';
import { ListDataModelsComponent } from './chart/list-data-models/list-data-models.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PercentChartComponent } from './chart/percent-chart/percent-chart.component';
import { CompletionChartComponent } from './chart/completion-chart/completion-chart.component';
import { DashboardExportComponent } from './dashboard-export/dashboard-export.component';
import { DialogContentAddUser } from './dialog-content-add-user/dialog-content-add-user';
import { DialogContentAddUserToTeamsComponent } from './dialog-content-add-user-to-teams/dialog-content-add-user-to-teams.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    LoginComponent,
    NotificationsComponent,
    ParcoursComponent,
    LoginErrorComponent,
    SettingsComponent,
    AccountComponent,
    DashboardComponent,
    TeamsComponent,
    CourseComponent,
    ModuleComponent,
    DocumentComponent,
    SkillComponent,
    ChartCardComponent,
    ScrollbarComponent,
    ExternalConnectComponent,
    StartcourseComponent,
    DialogContentImportScorm,
    DialogContentAddUser,
    DialogContentAddUserToTeamsComponent,
    DndDirective,
    ResultUserComponent,
    ListComponent,
    ClusterVerticalBarComponent,
    ListDataModelsComponent,
    PercentChartComponent,
    CompletionChartComponent,
    DashboardExportComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }), 
    MultiWindowModule.forRoot({
      heartbeat: 200,
      newWindowScan: 1500,
      messageTimeout: 10000,
      windowTimeout: 700,
    }),
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    HttpClientModule,
    MatDialogModule,
    MatTabsModule,
    MatButtonModule,
    MatRadioModule,
    BrowserModule,
    MatFileUploadModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    DragDropModule,
    MatTabsModule,
    MatExpansionModule,
    NgxChartsModule,
    NgxPaginationModule
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    {provide: MAT_DATE_LOCALE, useValue: 'fr'}
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoginErrorComponent, DialogContentImportScorm, DialogContentAddUser, DialogContentAddUserToTeamsComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}